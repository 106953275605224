import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
  Upload: any;
};

export enum App_Type {
  Android = 'ANDROID',
  Ios = 'IOS'
}

export type Address = {
  __typename?: 'Address';
  city: Scalars['String'];
  client: Client;
  country: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  street: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  zipCode: Scalars['String'];
};

export type AddressDataInput = {
  city: Scalars['String'];
  country: Scalars['String'];
  street: Scalars['String'];
  zipCode: Scalars['String'];
};

export type AdminCashRegisterResponseLoad = {
  __typename?: 'AdminCashRegisterResponseLoad';
  cashRegister?: Maybe<CashRegister>;
  id: Scalars['Int'];
  merchantId: Scalars['String'];
};

export type AdminGetCashRegisterReceiptResponse = {
  __typename?: 'AdminGetCashRegisterReceiptResponse';
  cashRegisterReceipt: Scalars['String'];
};

export type AdminGetCashRegistersResponse = {
  __typename?: 'AdminGetCashRegistersResponse';
  cashRegisters: Array<AdminCashRegisterResponseLoad>;
};

export type AdminGetInvoicesResponse = {
  __typename?: 'AdminGetInvoicesResponse';
  invoices: Array<AdminInvoiceResponseLoad>;
};

export type AdminGetPRsResponse = {
  __typename?: 'AdminGetPRsResponse';
  PRs: Array<AdminPaymentRequestResponseLoad>;
};

export type AdminGetUserAccountsResponse = {
  __typename?: 'AdminGetUserAccountsResponse';
  payoutAccounts: Array<AdminUserBankAccountResponseLoad>;
};

export type AdminGetUsersResponse = {
  __typename?: 'AdminGetUsersResponse';
  users: Array<AdminUserResponseLoad>;
};

export type AdminInvoiceResponseLoad = {
  __typename?: 'AdminInvoiceResponseLoad';
  id: Scalars['Int'];
  invoice?: Maybe<Invoice>;
  merchantId: Scalars['String'];
};

export type AdminPaymentRequestResponseLoad = {
  __typename?: 'AdminPaymentRequestResponseLoad';
  id: Scalars['Int'];
  merchantId: Scalars['String'];
  paymentRequest?: Maybe<PaymentRequest>;
};

export type AdminUserBankAccountResponseLoad = {
  __typename?: 'AdminUserBankAccountResponseLoad';
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isDefault?: Maybe<Scalars['Boolean']>;
  merchantId?: Maybe<Scalars['String']>;
  merchantStatus?: Maybe<Merchant_Status>;
  userEmail?: Maybe<Scalars['String']>;
  userId: Scalars['Int'];
};

export type AdminUserResponseLoad = {
  __typename?: 'AdminUserResponseLoad';
  accountDeletionRequestDate: Scalars['DateTime'];
  companyName?: Maybe<Scalars['String']>;
  companyType: Company_Type;
  email: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  invoiceCount: Scalars['Int'];
  invoiceValue: Scalars['Float'];
  isAccountDeletionRequested: Scalars['Boolean'];
  merchantId: Scalars['String'];
  paymentCount: Scalars['Int'];
  prValue: Scalars['Float'];
  referralId: Scalars['String'];
  status: User_Status;
};

export type AuthPayload = {
  __typename?: 'AuthPayload';
  message?: Maybe<Scalars['String']>;
  token: Scalars['String'];
  tokenExpire: Scalars['DateTime'];
  user: User;
};

export enum Bucket_Id {
  CompanyLogoBucket = 'COMPANY_LOGO_BUCKET',
  ExpenseBucket = 'EXPENSE_BUCKET',
  InvoiceBucket = 'INVOICE_BUCKET',
  ReceiptBucket = 'RECEIPT_BUCKET',
  VerificationBucket = 'VERIFICATION_BUCKET'
}

export type Bank = {
  __typename?: 'Bank';
  bank_id?: Maybe<Scalars['String']>;
  bank_name?: Maybe<Scalars['String']>;
  bank_swift?: Maybe<Scalars['String']>;
};

export enum Company_Category {
  Art = 'ART',
  Carrent = 'CARRENT',
  Education = 'EDUCATION',
  Fashion = 'FASHION',
  Food = 'FOOD',
  Freelancer = 'FREELANCER',
  Health = 'HEALTH',
  Hotel = 'HOTEL',
  Other = 'OTHER',
  Party = 'PARTY',
  Payment = 'PAYMENT',
  Travel = 'TRAVEL'
}

export enum Company_Type {
  Company = 'COMPANY',
  Individual = 'INDIVIDUAL'
}

export type CashRegister = {
  __typename?: 'CashRegister';
  cashRegisterNumber: Scalars['String'];
  comment: Scalars['String'];
  createdAt: Scalars['DateTime'];
  currency: Currency;
  discountAmount?: Maybe<Scalars['Int']>;
  discountPercent?: Maybe<Scalars['Float']>;
  expenses?: Maybe<Array<Expense>>;
  fees?: Maybe<Scalars['Float']>;
  fulfilled: Scalars['Boolean'];
  htmlReceipt?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  moneyReceived?: Maybe<Scalars['Float']>;
  moneyReturned?: Maybe<Scalars['Float']>;
  paidWith?: Maybe<Paid_With>;
  pdfUrl: Scalars['String'];
  products: Array<CashRegisterProduct>;
  reference: Scalars['String'];
  status: Invoice_Status;
  subTotal: Scalars['Float'];
  tax?: Maybe<Scalars['Float']>;
  total: Scalars['Float'];
  transferReceiptUrl?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user: User;
};

export type CashRegisterDataPayload = {
  __typename?: 'CashRegisterDataPayload';
  cashRegisterNumber?: Maybe<Scalars['String']>;
  cashRegisterUrl?: Maybe<Scalars['String']>;
  totalAmount?: Maybe<Scalars['Float']>;
};

export type CashRegisterInitQueryPayload = {
  __typename?: 'CashRegisterInitQueryPayload';
  cashRegisterCount?: Maybe<Scalars['Int']>;
  cashRegisterNumber?: Maybe<Scalars['String']>;
  fees?: Maybe<Scalars['Float']>;
  tax?: Maybe<Scalars['Float']>;
};

export type CashRegisterProduct = {
  __typename?: 'CashRegisterProduct';
  cashRegister: CashRegister;
  costOfProductsSold?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  discountAmount?: Maybe<Scalars['Float']>;
  discountPercent?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  price: Scalars['Int'];
  product: Product;
  quantity: Scalars['Int'];
  quantityUnit: QuantityUnit;
  type: Product_Type;
  updatedAt: Scalars['DateTime'];
};

export type CashRegisterProductInput = {
  costOfProductsSold?: InputMaybe<Scalars['Int']>;
  discountAmount?: InputMaybe<Scalars['Int']>;
  discountPercent?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  price: Scalars['Int'];
  productId: Scalars['Int'];
  quantity: Scalars['Int'];
  quantityUnit: QuantityUnitInput;
  type: Product_Type;
};

export type Client = {
  __typename?: 'Client';
  address?: Maybe<Address>;
  contactPerson: Scalars['String'];
  createdAt: Scalars['DateTime'];
  customerName: Scalars['String'];
  discount: Scalars['Int'];
  email: Scalars['String'];
  id: Scalars['Int'];
  isFavorite: Scalars['Boolean'];
  notes: Scalars['String'];
  phoneNumber: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
  website: Scalars['String'];
};

export type ClientDataInput = {
  address: AddressDataInput;
  contactPerson: Scalars['String'];
  customerName: Scalars['String'];
  discount?: InputMaybe<Scalars['Int']>;
  email: Scalars['String'];
  isFavorite?: InputMaybe<Scalars['Boolean']>;
  notes?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

export type CompanyDataPayload = {
  __typename?: 'CompanyDataPayload';
  companyLogo?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
};

export type CompatibleResult = {
  __typename?: 'CompatibleResult';
  compatible?: Maybe<Scalars['Boolean']>;
  isSuccess: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type CreateCashRegisterInput = {
  cashRegisterLocalPath?: InputMaybe<Scalars['String']>;
  cashRegisterNumber?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  discountAmount?: InputMaybe<Scalars['Int']>;
  discountPercent?: InputMaybe<Scalars['Float']>;
  fees?: InputMaybe<Scalars['Float']>;
  fulfilled?: InputMaybe<Scalars['Boolean']>;
  localCashRegisterId?: InputMaybe<Scalars['String']>;
  moneyReceived?: InputMaybe<Scalars['Float']>;
  moneyReturned?: InputMaybe<Scalars['Float']>;
  paidWith?: InputMaybe<Paid_With>;
  pdfUrl?: InputMaybe<Scalars['String']>;
  products?: InputMaybe<Array<InputMaybe<CashRegisterProductInput>>>;
  reference?: InputMaybe<Scalars['String']>;
  subTotal?: InputMaybe<Scalars['Int']>;
  tax?: InputMaybe<Scalars['Float']>;
  total?: InputMaybe<Scalars['Int']>;
  transferReceiptUrl?: InputMaybe<Scalars['String']>;
};

export type CreateCashRegisterResult = {
  __typename?: 'CreateCashRegisterResult';
  expenses?: Maybe<Array<Maybe<Expense>>>;
  id?: Maybe<Scalars['Int']>;
  isSuccess: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  paymentFees?: Maybe<Scalars['Float']>;
  pdfUrl?: Maybe<Scalars['String']>;
  qrisCode?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  transferReceiptUrl?: Maybe<Scalars['String']>;
};

export type CreateClientResult = {
  __typename?: 'CreateClientResult';
  client?: Maybe<Client>;
  isSuccess: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type CreateInvoiceResult = {
  __typename?: 'CreateInvoiceResult';
  id?: Maybe<Scalars['Int']>;
  isSuccess: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  pdfUrl?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
};

export type Currency = {
  __typename?: 'Currency';
  decimalNumbers?: Maybe<Scalars['Int']>;
  decimalSeparator?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isoCode?: Maybe<Scalars['String']>;
  position?: Maybe<Fix_Type>;
  shortFormat?: Maybe<Scalars['String']>;
  thousandSeparator?: Maybe<Scalars['String']>;
};

export type DeleteUserDataResult = {
  __typename?: 'DeleteUserDataResult';
  isSuccess: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  userData?: Maybe<AdminUserResponseLoad>;
};

export type DetailedReportResult = {
  __typename?: 'DetailedReportResult';
  costOfGoodsSold?: Maybe<Scalars['Float']>;
  grossProfit?: Maybe<Scalars['Float']>;
  incomeBeforeTax?: Maybe<Scalars['Float']>;
  msg?: Maybe<Scalars['String']>;
  profit?: Maybe<Scalars['Float']>;
  revenue?: Maybe<Scalars['Float']>;
  tax?: Maybe<Scalars['Float']>;
  totalOperationCost?: Maybe<Scalars['Float']>;
};

export enum Expense_Recurrence {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  None = 'NONE',
  Occasionally = 'OCCASIONALLY',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY'
}

export enum Expense_Type {
  CostsOfGoodsSold = 'COSTS_OF_GOODS_SOLD',
  FoodAndDrink = 'FOOD_AND_DRINK',
  OtherExpense = 'OTHER_EXPENSE',
  Packaging = 'PACKAGING',
  RentAndUtilities = 'RENT_AND_UTILITIES',
  Savings = 'SAVINGS',
  StaffSallary = 'STAFF_SALLARY',
  Transportation = 'TRANSPORTATION'
}

export type Expense = {
  __typename?: 'Expense';
  cashRegister?: Maybe<CashRegister>;
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Boolean'];
  expenseName: Scalars['String'];
  id: Scalars['Int'];
  imageIds: Scalars['JSON'];
  invoice?: Maybe<Invoice>;
  price: Scalars['Int'];
  recurrence: Expense_Recurrence;
  type: Expense_Type;
  updatedAt: Scalars['DateTime'];
  user: User;
};

export type ExpenseImagesInput = {
  data: Scalars['String'];
  fileType?: InputMaybe<Scalars['String']>;
};

export type ExpenseInput = {
  expenseName: Scalars['String'];
  imageIds: Array<Scalars['Int']>;
  isRecurring?: InputMaybe<Scalars['Boolean']>;
  price: Scalars['Int'];
  recurrence?: InputMaybe<Expense_Recurrence>;
  type?: InputMaybe<Expense_Type>;
};

export type ExpenseResult = {
  __typename?: 'ExpenseResult';
  expense?: Maybe<Expense>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSuccess: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type ExpensesResult = {
  __typename?: 'ExpensesResult';
  expenses?: Maybe<Array<Expense>>;
  isSuccess: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export enum Fix_Type {
  Prefix = 'PREFIX',
  Suffix = 'SUFFIX'
}

export type File = {
  data: Scalars['String'];
  fileType: Scalars['String'];
};

export type FilePayload = {
  __typename?: 'FilePayload';
  url: Scalars['String'];
};

export type GetCashRegisterByReferenceResult = {
  __typename?: 'GetCashRegisterByReferenceResult';
  cashRegisterData?: Maybe<CashRegisterDataPayload>;
  companyData?: Maybe<CompanyDataPayload>;
  isFullFilled?: Maybe<Scalars['Boolean']>;
  isSuccess: Scalars['Boolean'];
  isVerified?: Maybe<Scalars['Boolean']>;
  receiptUrl?: Maybe<Scalars['String']>;
};

export type GetImagesResult = {
  __typename?: 'GetImagesResult';
  images: Array<Image>;
  isSuccess: Scalars['Boolean'];
};

export type GetInvoiceByReferenceResult = {
  __typename?: 'GetInvoiceByReferenceResult';
  companyData?: Maybe<CompanyDataPayload>;
  invoiceData?: Maybe<InvoiceDataPayload>;
  isFullFilled?: Maybe<Scalars['Boolean']>;
  isSuccess: Scalars['Boolean'];
  isVerified?: Maybe<Scalars['Boolean']>;
  receiptUrl?: Maybe<Scalars['String']>;
  voucherType: Voucher_Type;
};

export type GetLastTransactionsResult = {
  __typename?: 'GetLastTransactionsResult';
  isSuccess: Scalars['Boolean'];
  transactions?: Maybe<Array<GetTransactionType>>;
  transactionsString?: Maybe<Scalars['String']>;
};

export type GetMerchantBalanceResult = {
  __typename?: 'GetMerchantBalanceResult';
  amount?: Maybe<Scalars['Float']>;
  isSuccess: Scalars['Boolean'];
};

export type GetMerchantDataResult = {
  __typename?: 'GetMerchantDataResult';
  balance?: Maybe<Scalars['Float']>;
  bank_account?: Maybe<Scalars['String']>;
  bank_id?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  merchant_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  personal_id?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  status_flag?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  wallet_id?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type GetMerchantResult = {
  __typename?: 'GetMerchantResult';
  balance: Scalars['Float'];
  merchant?: Maybe<Merchant>;
  status: Merchant_Status;
};

export type GetTransactionRealTimeStatusResult = {
  __typename?: 'GetTransactionRealTimeStatusResult';
  expenses?: Maybe<Array<Maybe<Expense>>>;
  isFullFilled?: Maybe<Scalars['Boolean']>;
  isSuccess: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  status?: Maybe<Transaction_Status>;
};

export type GetTransactionResult = {
  __typename?: 'GetTransactionResult';
  entityNumber: Scalars['String'];
  entityReference: Scalars['String'];
  entityType: Payment_Type;
  entityUrl: Scalars['String'];
  receiptUrl: Scalars['String'];
  status: Transaction_Status;
};

export type GetTransactionStatusResult = {
  __typename?: 'GetTransactionStatusResult';
  cashRegister?: Maybe<Array<Maybe<InvoiceStatus>>>;
  invoice?: Maybe<Array<Maybe<InvoiceStatus>>>;
  isSuccess: Scalars['Boolean'];
  paymentRequest?: Maybe<Array<Maybe<InvoiceStatus>>>;
};

export type GetTransactionType = {
  __typename?: 'GetTransactionType';
  amount: Scalars['Float'];
  date: Scalars['DateTime'];
  elementId: Scalars['Int'];
  finalAmount: Scalars['Float'];
  id: Scalars['String'];
  reference: Scalars['String'];
  title: Scalars['String'];
  type: Transaction_Type;
};

export enum Invoice_Status {
  Created = 'CREATED',
  Deleted = 'DELETED'
}

export type Image = {
  __typename?: 'Image';
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Boolean'];
  id: Scalars['Int'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
  user: User;
};

export type Invoice = {
  __typename?: 'Invoice';
  client?: Maybe<Client>;
  clientEmail: Scalars['String'];
  clientId?: Maybe<Scalars['Int']>;
  clientName: Scalars['String'];
  clinetAddress: Scalars['String'];
  comment: Scalars['String'];
  createdAt: Scalars['DateTime'];
  currency: Currency;
  discountPercent: Scalars['Float'];
  expenses?: Maybe<Array<Expense>>;
  fees: Scalars['Float'];
  fulfilled: Scalars['Boolean'];
  id: Scalars['Int'];
  includeFees: Scalars['Boolean'];
  invoiceNumber: Scalars['String'];
  payTerm: Scalars['DateTime'];
  pdfUrl: Scalars['String'];
  products: Array<InvoiceProduct>;
  reference: Scalars['String'];
  shipping: Scalars['Float'];
  status: Invoice_Status;
  subTotal: Scalars['Float'];
  tax: Scalars['Float'];
  taxPercent: Scalars['Float'];
  total: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  user: User;
};

export type InvoiceDataPayload = {
  __typename?: 'InvoiceDataPayload';
  invoiceNumber?: Maybe<Scalars['String']>;
  invoiceUrl?: Maybe<Scalars['String']>;
  isFeeEnabled?: Maybe<Scalars['Boolean']>;
  totalAmount?: Maybe<Scalars['Float']>;
};

export type InvoiceInput = {
  clientId: Scalars['Int'];
  comment?: InputMaybe<Scalars['String']>;
  discountPercent?: InputMaybe<Scalars['Float']>;
  fulfilled?: InputMaybe<Scalars['Boolean']>;
  includeFees?: InputMaybe<Scalars['Boolean']>;
  invoiceLocalPath?: InputMaybe<Scalars['String']>;
  invoiceNumber?: InputMaybe<Scalars['String']>;
  localInvoiceId?: InputMaybe<Scalars['String']>;
  payTerm?: InputMaybe<Scalars['DateTime']>;
  pdfUrl?: InputMaybe<Scalars['String']>;
  products?: InputMaybe<Array<InputMaybe<InvoiceProductInput>>>;
  reference?: InputMaybe<Scalars['String']>;
  shipping?: InputMaybe<Scalars['Float']>;
  taxPercent?: InputMaybe<Scalars['Float']>;
};

export type InvoiceMerchant = {
  __typename?: 'InvoiceMerchant';
  merchantId?: Maybe<Scalars['String']>;
  status?: Maybe<Merchant_Status>;
};

export type InvoiceNumberPayload = {
  __typename?: 'InvoiceNumberPayload';
  invoiceCount?: Maybe<Scalars['Int']>;
  invoiceNumber?: Maybe<Scalars['String']>;
};

export type InvoiceProduct = {
  __typename?: 'InvoiceProduct';
  costOfProductsSold: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  invoice: Invoice;
  name: Scalars['String'];
  price: Scalars['Int'];
  product: Product;
  quantity: Scalars['Int'];
  quantityUnit: QuantityUnit;
  type: Product_Type;
  updatedAt: Scalars['DateTime'];
};

export type InvoiceProductInput = {
  costOfProductsSold: Scalars['Int'];
  name: Scalars['String'];
  price: Scalars['Int'];
  productId: Scalars['Int'];
  quantity: Scalars['Int'];
  quantityUnit: QuantityUnitInput;
  type: Product_Type;
};

export type InvoiceStatus = {
  __typename?: 'InvoiceStatus';
  isFullFilled: Scalars['Boolean'];
  reference: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type Issue = {
  __typename?: 'Issue';
  id: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  user: User;
};

export type IssueInput = {
  message?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type KeysInput = {
  key: Scalars['String'];
  secret: Scalars['String'];
};

export enum Language {
  En = 'en',
  Id = 'id'
}

export type LanguageResult = {
  __typename?: 'LanguageResult';
  isSuccess: Scalars['Boolean'];
  language?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export enum Merchant_Status {
  Approved = 'APPROVED',
  Deleted = 'DELETED',
  Denied = 'DENIED',
  Inavtive = 'INAVTIVE',
  NotRegistered = 'NOT_REGISTERED'
}

export type MePayload = {
  __typename?: 'MePayload';
  accounts?: Maybe<Array<Maybe<PayoutAccount>>>;
  cashRegisterNumber?: Maybe<Scalars['Int']>;
  cashRegisters?: Maybe<Array<Maybe<CashRegister>>>;
  clients?: Maybe<Array<Maybe<Client>>>;
  compatible: Scalars['Boolean'];
  currency?: Maybe<Currency>;
  customerServiceNumber?: Maybe<Scalars['String']>;
  expenses: Array<Expense>;
  fixFee?: Maybe<Scalars['Int']>;
  invoiceNumber?: Maybe<Scalars['Int']>;
  invoices?: Maybe<Array<Maybe<Invoice>>>;
  paymentRequests?: Maybe<Array<Maybe<PaymentRequest>>>;
  percentFee?: Maybe<Scalars['Float']>;
  products?: Maybe<Array<Maybe<Product>>>;
  referrals?: Maybe<Array<Referral>>;
  user?: Maybe<User>;
};

export type Merchant = {
  __typename?: 'Merchant';
  IDNumber?: Maybe<Scalars['String']>;
  businessName?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  merchantId: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  status: Merchant_Status;
  updatedAt: Scalars['DateTime'];
  user: User;
  userAddress?: Maybe<Array<Maybe<UserAddress>>>;
  verification?: Maybe<Verification>;
};

export type MerchantDataInput = {
  bankAccount?: InputMaybe<Scalars['String']>;
  bankId?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  street: Scalars['String'];
  walletId?: InputMaybe<Scalars['String']>;
  zipCode: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  adminAuth: AuthPayload;
  authWithSocial: AuthPayload;
  authWithToken: AuthPayload;
  changeCurrency: Result;
  changeLanguage: LanguageResult;
  createCashRegister: CreateCashRegisterResult;
  createClient: CreateClientResult;
  createExpense: ExpenseResult;
  createInvoice: CreateInvoiceResult;
  createIssue: Result;
  createPaymentRequest: PaymentRequestResult;
  createPayoutAccount: PayoutAccountResult;
  createProduct: ProductResult;
  createReferral: Result;
  createVerification: Result;
  deleteClient: Result;
  deleteExpense: Result;
  deleteInvoice: Result;
  deletePaymentRequest: Result;
  deletePayoutAccount: Result;
  deleteProduct: Result;
  deleteUserData: DeleteUserDataResult;
  logIn: AuthPayload;
  loginToken: Result;
  removeUserFCM: Result;
  saveUserFCM: Result;
  sendReceipt: Result;
  setDefaultPayoutAccount: Result;
  setFulfilled: Result;
  setMerchantStatus?: Maybe<Merchant>;
  setPaymentRequestFulfilled: Result;
  setPayoutStatus?: Maybe<Result>;
  setTransactionStatus?: Maybe<Result>;
  signUp: AuthPayload;
  startPayment: StartPaymentResult;
  startPayout: Result;
  toggleFavorite: Result;
  updateClient: Result;
  updateExpense: ExpenseResult;
  updateMerchantData: Result;
  updatePayoutAccount: Result;
  updateProduct: ProductResult;
  updateUserData: Result;
  uploadCashRegisterReceipt: UploadCashRegisterReceiptResult;
  uploadCompanyLogo: Result;
  uploadImage: UploadImageResult;
  uploadVerificationImage: Result;
};


export type MutationAdminAuthArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationAuthWithSocialArgs = {
  referralId?: InputMaybe<Scalars['String']>;
  socialType?: InputMaybe<Social_Type>;
  token?: InputMaybe<Scalars['String']>;
};


export type MutationAuthWithTokenArgs = {
  token: Scalars['String'];
};


export type MutationChangeCurrencyArgs = {
  id: Scalars['Int'];
};


export type MutationChangeLanguageArgs = {
  language: Language;
};


export type MutationCreateCashRegisterArgs = {
  data: CreateCashRegisterInput;
  pdf?: InputMaybe<Scalars['String']>;
  receiptData?: InputMaybe<ReceiptDataProps>;
  transferImage?: InputMaybe<Scalars['String']>;
};


export type MutationCreateClientArgs = {
  client: ClientDataInput;
};


export type MutationCreateExpenseArgs = {
  expense: ExpenseInput;
};


export type MutationCreateInvoiceArgs = {
  data: InvoiceInput;
  pdf: Scalars['String'];
};


export type MutationCreateIssueArgs = {
  message: Scalars['String'];
  title: Scalars['String'];
};


export type MutationCreatePaymentRequestArgs = {
  data: PaymentRequestInput;
};


export type MutationCreatePayoutAccountArgs = {
  account: PayoutAccountInput;
};


export type MutationCreateProductArgs = {
  product: ProductInput;
};


export type MutationCreateVerificationArgs = {
  businessDescription: Scalars['String'];
  employees?: InputMaybe<Scalars['Int']>;
  socialMediaLink?: InputMaybe<Scalars['String']>;
  websiteLink?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteClientArgs = {
  clientId: Scalars['Int'];
};


export type MutationDeleteExpenseArgs = {
  expenseId: Scalars['Int'];
};


export type MutationDeleteInvoiceArgs = {
  invoiceId: Scalars['Int'];
};


export type MutationDeletePaymentRequestArgs = {
  paymentRequestId: Scalars['Int'];
};


export type MutationDeletePayoutAccountArgs = {
  accountId: Scalars['Int'];
};


export type MutationDeleteProductArgs = {
  productId: Scalars['Int'];
};


export type MutationDeleteUserDataArgs = {
  userId: Scalars['Int'];
};


export type MutationLogInArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationLoginTokenArgs = {
  email: Scalars['String'];
};


export type MutationRemoveUserFcmArgs = {
  token: Scalars['String'];
};


export type MutationSaveUserFcmArgs = {
  appType: App_Type;
  appVersion: Scalars['String'];
  token: Scalars['String'];
};


export type MutationSendReceiptArgs = {
  lang?: InputMaybe<Language>;
  receiptData: ReceiptDataProps;
  recipientReceipt: ReceiptRecipientProps;
  senderReceipt: ReceiptSenderProps;
};


export type MutationSetDefaultPayoutAccountArgs = {
  accountId: Scalars['Int'];
  value?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSetFulfilledArgs = {
  invoiceId: Scalars['Int'];
};


export type MutationSetMerchantStatusArgs = {
  keys: KeysInput;
  merchantId: Scalars['String'];
  status: Merchant_Status;
};


export type MutationSetPaymentRequestFulfilledArgs = {
  paymentRequestId: Scalars['Int'];
};


export type MutationSetPayoutStatusArgs = {
  keys: KeysInput;
  merchantId: Scalars['String'];
  success: Scalars['Boolean'];
};


export type MutationSetTransactionStatusArgs = {
  keys: KeysInput;
  linkHash: Scalars['String'];
  status: Transaction_Status;
};


export type MutationSignUpArgs = {
  companyName?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  fullName?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  referralId?: InputMaybe<Scalars['String']>;
};


export type MutationStartPaymentArgs = {
  method: Scalars['String'];
  ovoId?: InputMaybe<Scalars['String']>;
  reference: Scalars['String'];
};


export type MutationStartPayoutArgs = {
  accountId?: InputMaybe<Scalars['Int']>;
  amount: Scalars['Float'];
  senderNote?: InputMaybe<Scalars['String']>;
};


export type MutationToggleFavoriteArgs = {
  clientId: Scalars['Int'];
  way?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateClientArgs = {
  client: ClientDataInput;
  clientId: Scalars['Int'];
};


export type MutationUpdateExpenseArgs = {
  expense: ExpenseInput;
  expenseId: Scalars['Int'];
};


export type MutationUpdateMerchantDataArgs = {
  data: MerchantDataInput;
};


export type MutationUpdatePayoutAccountArgs = {
  account: PayoutAccountInput;
  accountId: Scalars['Int'];
};


export type MutationUpdateProductArgs = {
  product: ProductInput;
  productId: Scalars['Int'];
};


export type MutationUpdateUserDataArgs = {
  data?: InputMaybe<UpdateUserDataInput>;
};


export type MutationUploadCashRegisterReceiptArgs = {
  pdf: Scalars['String'];
  reference: Scalars['String'];
};


export type MutationUploadCompanyLogoArgs = {
  fileType: Scalars['String'];
  logo: Scalars['String'];
};


export type MutationUploadImageArgs = {
  bucketId: Bucket_Id;
  data: File;
  name?: InputMaybe<Scalars['String']>;
};


export type MutationUploadVerificationImageArgs = {
  data: File;
  imageKey: Scalars['String'];
  verificationId: Scalars['Int'];
};

export enum Paid_With {
  Cash = 'CASH',
  QrCode = 'QR_CODE',
  Transfer = 'TRANSFER'
}

export enum Payment_Type {
  Invoice = 'INVOICE',
  PaymentRequest = 'PAYMENT_REQUEST'
}

export enum Payout_Account_Type {
  Bank = 'BANK',
  Wallet = 'WALLET'
}

export enum Product_Type {
  Product = 'PRODUCT',
  Service = 'SERVICE'
}

export type PaymentRequest = {
  __typename?: 'PaymentRequest';
  amount: Scalars['Float'];
  client?: Maybe<Client>;
  clientId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  fees: Scalars['Float'];
  id: Scalars['Int'];
  includeFees: Scalars['Boolean'];
  isFullFilled: Scalars['Boolean'];
  name: Scalars['String'];
  notice: Scalars['String'];
  reference: Scalars['String'];
  transactions?: Maybe<Array<Maybe<PaymentServiceTransaction>>>;
  updatedAt: Scalars['DateTime'];
  user: User;
};

export type PaymentRequestInput = {
  amount?: InputMaybe<Scalars['Float']>;
  clientId: Scalars['Int'];
  includeFees?: InputMaybe<Scalars['Boolean']>;
  notice?: InputMaybe<Scalars['String']>;
};

export type PaymentRequestResult = {
  __typename?: 'PaymentRequestResult';
  error?: Maybe<Scalars['String']>;
  isSuccess: Scalars['Boolean'];
  paymentRequest?: Maybe<PaymentRequest>;
};

export type PaymentServiceConfig = {
  __typename?: 'PaymentServiceConfig';
  alfamart: Scalars['Float'];
  cardPayment: Scalars['Float'];
  cr_blocked?: Maybe<Scalars['Int']>;
  ovo: Scalars['Float'];
  qris: Scalars['Float'];
  tax: Scalars['Float'];
  virtualAccount: Scalars['Float'];
  wallet: Scalars['Float'];
};

export type PaymentServiceTransaction = {
  __typename?: 'PaymentServiceTransaction';
  amount?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['String']>;
};

export type PayoutAccount = {
  __typename?: 'PayoutAccount';
  accountName: Scalars['String'];
  accountValue: Scalars['String'];
  bankId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  default: Scalars['Boolean'];
  id: Scalars['Int'];
  nickname: Scalars['String'];
  type: Payout_Account_Type;
  updatedAt: Scalars['DateTime'];
  user: User;
};

export type PayoutAccountInput = {
  accountName: Scalars['String'];
  accountValue: Scalars['String'];
  bankId?: InputMaybe<Scalars['String']>;
  default?: InputMaybe<Scalars['Boolean']>;
  nickname: Scalars['String'];
  type: Payout_Account_Type;
};

export type PayoutAccountResult = {
  __typename?: 'PayoutAccountResult';
  account?: Maybe<PayoutAccount>;
  isSuccess: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type PayoutAccountsResult = {
  __typename?: 'PayoutAccountsResult';
  accounts?: Maybe<Array<PayoutAccount>>;
  isSuccess: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type Product = {
  __typename?: 'Product';
  barcode?: Maybe<Scalars['String']>;
  category?: Maybe<ProductCategory>;
  costOfProductsSold: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  price: Scalars['Int'];
  productImage?: Maybe<Scalars['String']>;
  quantityUnit: QuantityUnit;
  sku?: Maybe<Scalars['String']>;
  type: Product_Type;
  updatedAt: Scalars['DateTime'];
  user: User;
};

export type ProductCategory = {
  __typename?: 'ProductCategory';
  color?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  user: User;
};

export type ProductCategoryInput = {
  color?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
};

export type ProductInput = {
  barcode?: InputMaybe<Scalars['String']>;
  base64?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<ProductCategoryInput>;
  costOfProductsSold?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Int'];
  productImage?: InputMaybe<Scalars['String']>;
  quantityUnit: QuantityUnitInput;
  sku?: InputMaybe<Scalars['String']>;
  type: Product_Type;
};

export type ProductResult = {
  __typename?: 'ProductResult';
  categories?: Maybe<Array<Maybe<ProductCategory>>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSuccess: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
};

export type ProductsResult = {
  __typename?: 'ProductsResult';
  categories?: Maybe<Array<ProductCategory>>;
  isSuccess: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Product>>;
};

export type ProfitAmountResult = {
  __typename?: 'ProfitAmountResult';
  msg?: Maybe<Scalars['String']>;
  profitAmount?: Maybe<Scalars['Float']>;
};

export type QuantityUnit = {
  __typename?: 'QuantityUnit';
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type QuantityUnitInput = {
  name: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  cashRegisterInit: CashRegisterInitQueryPayload;
  compatible: CompatibleResult;
  crGetByReference: GetCashRegisterByReferenceResult;
  crReferenceValid: ReferenceValidPayload;
  currencies?: Maybe<Array<Maybe<Currency>>>;
  detailedReport: DetailedReportResult;
  getBanks: Array<Bank>;
  getByReference: GetInvoiceByReferenceResult;
  getCashRegisterReceipt: AdminGetCashRegisterReceiptResponse;
  getCashRegisters: AdminGetCashRegistersResponse;
  getClients: Array<Client>;
  getExpense: ExpenseResult;
  getExpenses: ExpensesResult;
  getImages: GetImagesResult;
  getInvoices: AdminGetInvoicesResponse;
  getLastTransactions: GetLastTransactionsResult;
  getMerchant?: Maybe<Merchant>;
  getMerchantBalance: GetMerchantBalanceResult;
  getMerchantData: GetMerchantDataResult;
  getMerchantV2: GetMerchantResult;
  getPRs: AdminGetPRsResponse;
  getPaymentConfig: PaymentServiceConfig;
  getPayoutAccount: PayoutAccountResult;
  getPayoutAccounts: PayoutAccountsResult;
  getProduct: ProductResult;
  getProducts: ProductsResult;
  getTransaction: GetTransactionResult;
  getTransactionRealTimeStatus: GetTransactionRealTimeStatusResult;
  getTransactionStatus: GetTransactionStatusResult;
  getUserBankAccounts: AdminGetUserAccountsResponse;
  getUsers: AdminGetUsersResponse;
  getVerificationByMerchant?: Maybe<Array<VerificationImage>>;
  invoiceNumber: InvoiceNumberPayload;
  me: MePayload;
  profitAmount: ProfitAmountResult;
  referenceValid: ReferenceValidPayload;
};


export type QueryCrGetByReferenceArgs = {
  reference: Scalars['String'];
};


export type QueryCrReferenceValidArgs = {
  reference: Scalars['String'];
};


export type QueryDetailedReportArgs = {
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};


export type QueryGetByReferenceArgs = {
  reference: Scalars['String'];
};


export type QueryGetCashRegisterReceiptArgs = {
  cashRegisterId: Scalars['Int'];
};


export type QueryGetClientsArgs = {
  onlyFavorites?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGetExpenseArgs = {
  expenseId: Scalars['Int'];
};


export type QueryGetImagesArgs = {
  ids: Array<Scalars['Int']>;
};


export type QueryGetLastTransactionsArgs = {
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryGetPaymentConfigArgs = {
  reference: Scalars['String'];
};


export type QueryGetPayoutAccountArgs = {
  accountId: Scalars['Int'];
};


export type QueryGetProductArgs = {
  productId: Scalars['Int'];
};


export type QueryGetTransactionArgs = {
  reference: Scalars['String'];
};


export type QueryGetTransactionRealTimeStatusArgs = {
  reference: Scalars['String'];
};


export type QueryGetVerificationByMerchantArgs = {
  keys: KeysInput;
  merchantId: Scalars['String'];
};


export type QueryMeArgs = {
  language?: InputMaybe<Language>;
};


export type QueryReferenceValidArgs = {
  reference: Scalars['String'];
};

export enum Referral_Status {
  Sent = 'SENT',
  SuccessfullReferral = 'SUCCESSFULL_REFERRAL',
  UserRegistered = 'USER_REGISTERED'
}

export type ReceiptDataProps = {
  paidWith: Paid_With;
  receiptNumber: Scalars['String'];
  receiptTime: Scalars['String'];
  section?: InputMaybe<Array<InputMaybe<SectionProps>>>;
};

export type ReceiptRecipientProps = {
  email: Scalars['String'];
  recipientName?: InputMaybe<Scalars['String']>;
};

export type ReceiptSenderProps = {
  address?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  logo?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  senderName: Scalars['String'];
  website?: InputMaybe<Scalars['String']>;
};

export type ReferenceValidPayload = {
  __typename?: 'ReferenceValidPayload';
  isValid: Scalars['Boolean'];
};

export type Referral = {
  __typename?: 'Referral';
  amountPaid: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  referredBy?: Maybe<User>;
  referredUser?: Maybe<ReferredUser>;
  status: Referral_Status;
  updatedAt: Scalars['DateTime'];
  userNotified: Scalars['Boolean'];
};

export type ReferredUser = {
  __typename?: 'ReferredUser';
  fullName: Scalars['String'];
};

export type Result = {
  __typename?: 'Result';
  isSuccess: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export enum Social_Type {
  Apple = 'APPLE',
  Facebook = 'FACEBOOK',
  Google = 'GOOGLE'
}

export type SectionDataProps = {
  bold?: InputMaybe<Scalars['Boolean']>;
  info?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
  value: Scalars['String'];
};

export type SectionProps = {
  sectionData?: InputMaybe<Array<SectionDataProps>>;
};

export type StartPaymentResult = {
  __typename?: 'StartPaymentResult';
  error?: Maybe<Scalars['String']>;
  isSuccess: Scalars['Boolean'];
  method?: Maybe<Scalars['String']>;
  paymentReference: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  newUser: User;
};

export enum Transaction_Status {
  Failed = 'FAILED',
  Inited = 'INITED',
  Successful = 'SUCCESSFUL',
  Unkown = 'UNKOWN'
}

export enum Transaction_Type {
  Invoice = 'INVOICE',
  PaymentRequest = 'PAYMENT_REQUEST',
  Payout = 'PAYOUT',
  Refund = 'REFUND'
}

export enum User_Status {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
}

export type UpdateUserDataInput = {
  cashRegisterFees?: InputMaybe<Scalars['Float']>;
  cashRegisterTax?: InputMaybe<Scalars['Float']>;
  companyAddress?: InputMaybe<Scalars['String']>;
  companyCategory?: InputMaybe<Company_Category>;
  companyCategoryOther?: InputMaybe<Scalars['String']>;
  companyDescription?: InputMaybe<Scalars['String']>;
  companyEmailAdress?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  companyNumber?: InputMaybe<Scalars['String']>;
  companySocial?: InputMaybe<Scalars['String']>;
  companyType?: InputMaybe<Company_Type>;
  companyWebsite?: InputMaybe<Scalars['String']>;
  currencyId?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  isAccountDeletionRequested?: InputMaybe<Scalars['Boolean']>;
  isFeeEnabled?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
};

export type UploadCashRegisterReceiptResult = {
  __typename?: 'UploadCashRegisterReceiptResult';
  id?: Maybe<Scalars['Int']>;
  isSuccess: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  pdfUrl?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
};

export type UploadImageResult = {
  __typename?: 'UploadImageResult';
  image?: Maybe<Image>;
  isSuccess: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  Merchant?: Maybe<Merchant>;
  cashRegisterFees?: Maybe<Scalars['Float']>;
  cashRegisterTax?: Maybe<Scalars['Float']>;
  companyAddress?: Maybe<Scalars['String']>;
  companyDescription?: Maybe<Scalars['String']>;
  companyEmailAdress?: Maybe<Scalars['String']>;
  companyLogo?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  companyNumber?: Maybe<Scalars['String']>;
  companySocial?: Maybe<Scalars['String']>;
  companyType: Company_Type;
  companyWebsite?: Maybe<Scalars['String']>;
  currency?: Maybe<Currency>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isAccountDeletionRequested?: Maybe<Scalars['Boolean']>;
  isFeeEnabled?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Language>;
  lastName?: Maybe<Scalars['String']>;
  referralId?: Maybe<Scalars['String']>;
  socialId?: Maybe<Scalars['String']>;
  status?: Maybe<User_Status>;
  verifications?: Maybe<Array<Verification>>;
};

export type UserAddress = {
  __typename?: 'UserAddress';
  address: Scalars['String'];
  id: Scalars['ID'];
  merchant: Merchant;
  merchantId: Scalars['Int'];
};

export enum Verification_Status {
  Approved = 'APPROVED',
  Denied = 'DENIED',
  Initiated = 'INITIATED'
}

export enum Voucher_Type {
  Invoice = 'INVOICE',
  Receipt = 'RECEIPT'
}

export type Verification = {
  __typename?: 'Verification';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  images?: Maybe<Array<Maybe<VerificationImage>>>;
  status: Verification_Status;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user: User;
};

export type VerificationImage = {
  __typename?: 'VerificationImage';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  key: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
};

export type VerificationInput = {
  idBack?: InputMaybe<Scalars['String']>;
  idFront?: InputMaybe<Scalars['String']>;
  selfie?: InputMaybe<Scalars['String']>;
  taxBack?: InputMaybe<Scalars['String']>;
  taxFront?: InputMaybe<Scalars['String']>;
};

export type AdminUserResponseLoadFragmentFragment = { __typename?: 'AdminUserResponseLoad', id: number, email: string, fullName?: string | null, companyType: Company_Type, companyName?: string | null, referralId: string, merchantId: string, invoiceCount: number, paymentCount: number, invoiceValue: number, prValue: number, isAccountDeletionRequested: boolean, accountDeletionRequestDate: any, status: User_Status };

export type AdminUserBankAccountResponseLoadFragmentFragment = { __typename?: 'AdminUserBankAccountResponseLoad', id: number, userId: number, userEmail?: string | null, accountName?: string | null, accountType?: string | null, accountNumber?: string | null, isDefault?: boolean | null, merchantId?: string | null, merchantStatus?: Merchant_Status | null };

export type AdminAuthMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type AdminAuthMutation = { __typename?: 'Mutation', adminAuth: { __typename?: 'AuthPayload', token: string, user: { __typename?: 'User', id: number, email: string } } };

export type GetCashRegistersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCashRegistersQuery = { __typename?: 'Query', getCashRegisters: { __typename?: 'AdminGetCashRegistersResponse', cashRegisters: Array<{ __typename?: 'AdminCashRegisterResponseLoad', id: number, merchantId: string, cashRegister?: { __typename?: 'CashRegister', id: number, reference: string, cashRegisterNumber: string, comment: string, htmlReceipt?: string | null, transferReceiptUrl?: string | null, total: number, paidWith?: Paid_With | null, fulfilled: boolean, tax?: number | null, fees?: number | null, updatedAt: any, createdAt: any, user: { __typename?: 'User', id: number, email: string } } | null }> } };

export type GetCashRegisterReceiptQueryVariables = Exact<{
  cashRegisterId: Scalars['Int'];
}>;


export type GetCashRegisterReceiptQuery = { __typename?: 'Query', getCashRegisterReceipt: { __typename?: 'AdminGetCashRegisterReceiptResponse', cashRegisterReceipt: string } };

export type GetInvoicesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetInvoicesQuery = { __typename?: 'Query', getInvoices: { __typename?: 'AdminGetInvoicesResponse', invoices: Array<{ __typename?: 'AdminInvoiceResponseLoad', id: number, merchantId: string, invoice?: { __typename?: 'Invoice', id: number, reference: string, clientName: string, clientEmail: string, invoiceNumber: string, payTerm: any, pdfUrl: string, total: number, status: Invoice_Status, fulfilled: boolean, includeFees: boolean, updatedAt: any, createdAt: any, user: { __typename?: 'User', id: number, email: string } } | null }> } };

export type GetPRsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPRsQuery = { __typename?: 'Query', getPRs: { __typename?: 'AdminGetPRsResponse', PRs: Array<{ __typename?: 'AdminPaymentRequestResponseLoad', id: number, merchantId: string, paymentRequest?: { __typename?: 'PaymentRequest', reference: string, name: string, email: string, amount: number, fees: number, notice: string, isFullFilled: boolean, updatedAt: any, user: { __typename?: 'User', id: number, email: string } } | null }> } };

export type GetUserBankAccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserBankAccountsQuery = { __typename?: 'Query', getUserBankAccounts: { __typename?: 'AdminGetUserAccountsResponse', payoutAccounts: Array<{ __typename?: 'AdminUserBankAccountResponseLoad', id: number, userId: number, userEmail?: string | null, accountName?: string | null, accountType?: string | null, accountNumber?: string | null, isDefault?: boolean | null, merchantId?: string | null, merchantStatus?: Merchant_Status | null }> } };

export type GetUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersQuery = { __typename?: 'Query', getUsers: { __typename?: 'AdminGetUsersResponse', users: Array<{ __typename?: 'AdminUserResponseLoad', id: number, email: string, fullName?: string | null, companyType: Company_Type, companyName?: string | null, referralId: string, merchantId: string, invoiceCount: number, paymentCount: number, invoiceValue: number, prValue: number, isAccountDeletionRequested: boolean, accountDeletionRequestDate: any, status: User_Status }> } };

export const AdminUserResponseLoadFragmentFragmentDoc = gql`
    fragment AdminUserResponseLoadFragment on AdminUserResponseLoad {
  id
  email
  fullName
  companyType
  companyName
  referralId
  merchantId
  invoiceCount
  paymentCount
  invoiceValue
  prValue
  isAccountDeletionRequested
  accountDeletionRequestDate
  status
}
    `;
export const AdminUserBankAccountResponseLoadFragmentFragmentDoc = gql`
    fragment AdminUserBankAccountResponseLoadFragment on AdminUserBankAccountResponseLoad {
  id
  userId
  userEmail
  accountName
  accountType
  accountNumber
  isDefault
  merchantId
  merchantStatus
}
    `;
export const AdminAuthDocument = gql`
    mutation adminAuth($email: String!, $password: String!) {
  adminAuth(email: $email, password: $password) {
    user {
      id
      email
    }
    token
  }
}
    `;
export type AdminAuthMutationFn = Apollo.MutationFunction<AdminAuthMutation, AdminAuthMutationVariables>;

/**
 * __useAdminAuthMutation__
 *
 * To run a mutation, you first call `useAdminAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminAuthMutation, { data, loading, error }] = useAdminAuthMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAdminAuthMutation(baseOptions?: Apollo.MutationHookOptions<AdminAuthMutation, AdminAuthMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminAuthMutation, AdminAuthMutationVariables>(AdminAuthDocument, options);
      }
export type AdminAuthMutationHookResult = ReturnType<typeof useAdminAuthMutation>;
export type AdminAuthMutationResult = Apollo.MutationResult<AdminAuthMutation>;
export type AdminAuthMutationOptions = Apollo.BaseMutationOptions<AdminAuthMutation, AdminAuthMutationVariables>;
export const GetCashRegistersDocument = gql`
    query GetCashRegisters {
  getCashRegisters {
    cashRegisters {
      id
      cashRegister {
        id
        user {
          id
          email
        }
        reference
        cashRegisterNumber
        comment
        htmlReceipt
        transferReceiptUrl
        total
        paidWith
        fulfilled
        tax
        fees
        updatedAt
        createdAt
      }
      merchantId
    }
  }
}
    `;

/**
 * __useGetCashRegistersQuery__
 *
 * To run a query within a React component, call `useGetCashRegistersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCashRegistersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCashRegistersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCashRegistersQuery(baseOptions?: Apollo.QueryHookOptions<GetCashRegistersQuery, GetCashRegistersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCashRegistersQuery, GetCashRegistersQueryVariables>(GetCashRegistersDocument, options);
      }
export function useGetCashRegistersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCashRegistersQuery, GetCashRegistersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCashRegistersQuery, GetCashRegistersQueryVariables>(GetCashRegistersDocument, options);
        }
export type GetCashRegistersQueryHookResult = ReturnType<typeof useGetCashRegistersQuery>;
export type GetCashRegistersLazyQueryHookResult = ReturnType<typeof useGetCashRegistersLazyQuery>;
export type GetCashRegistersQueryResult = Apollo.QueryResult<GetCashRegistersQuery, GetCashRegistersQueryVariables>;
export const GetCashRegisterReceiptDocument = gql`
    query GetCashRegisterReceipt($cashRegisterId: Int!) {
  getCashRegisterReceipt(cashRegisterId: $cashRegisterId) {
    cashRegisterReceipt
  }
}
    `;

/**
 * __useGetCashRegisterReceiptQuery__
 *
 * To run a query within a React component, call `useGetCashRegisterReceiptQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCashRegisterReceiptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCashRegisterReceiptQuery({
 *   variables: {
 *      cashRegisterId: // value for 'cashRegisterId'
 *   },
 * });
 */
export function useGetCashRegisterReceiptQuery(baseOptions: Apollo.QueryHookOptions<GetCashRegisterReceiptQuery, GetCashRegisterReceiptQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCashRegisterReceiptQuery, GetCashRegisterReceiptQueryVariables>(GetCashRegisterReceiptDocument, options);
      }
export function useGetCashRegisterReceiptLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCashRegisterReceiptQuery, GetCashRegisterReceiptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCashRegisterReceiptQuery, GetCashRegisterReceiptQueryVariables>(GetCashRegisterReceiptDocument, options);
        }
export type GetCashRegisterReceiptQueryHookResult = ReturnType<typeof useGetCashRegisterReceiptQuery>;
export type GetCashRegisterReceiptLazyQueryHookResult = ReturnType<typeof useGetCashRegisterReceiptLazyQuery>;
export type GetCashRegisterReceiptQueryResult = Apollo.QueryResult<GetCashRegisterReceiptQuery, GetCashRegisterReceiptQueryVariables>;
export const GetInvoicesDocument = gql`
    query GetInvoices {
  getInvoices {
    invoices {
      id
      invoice {
        id
        user {
          id
          email
        }
        reference
        clientName
        clientEmail
        invoiceNumber
        payTerm
        pdfUrl
        total
        status
        fulfilled
        includeFees
        updatedAt
        createdAt
      }
      merchantId
    }
  }
}
    `;

/**
 * __useGetInvoicesQuery__
 *
 * To run a query within a React component, call `useGetInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<GetInvoicesQuery, GetInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoicesQuery, GetInvoicesQueryVariables>(GetInvoicesDocument, options);
      }
export function useGetInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoicesQuery, GetInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoicesQuery, GetInvoicesQueryVariables>(GetInvoicesDocument, options);
        }
export type GetInvoicesQueryHookResult = ReturnType<typeof useGetInvoicesQuery>;
export type GetInvoicesLazyQueryHookResult = ReturnType<typeof useGetInvoicesLazyQuery>;
export type GetInvoicesQueryResult = Apollo.QueryResult<GetInvoicesQuery, GetInvoicesQueryVariables>;
export const GetPRsDocument = gql`
    query GetPRs {
  getPRs {
    PRs {
      id
      paymentRequest {
        user {
          id
          email
        }
        reference
        name
        email
        amount
        fees
        notice
        isFullFilled
        updatedAt
      }
      merchantId
    }
  }
}
    `;

/**
 * __useGetPRsQuery__
 *
 * To run a query within a React component, call `useGetPRsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPRsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPRsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPRsQuery(baseOptions?: Apollo.QueryHookOptions<GetPRsQuery, GetPRsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPRsQuery, GetPRsQueryVariables>(GetPRsDocument, options);
      }
export function useGetPRsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPRsQuery, GetPRsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPRsQuery, GetPRsQueryVariables>(GetPRsDocument, options);
        }
export type GetPRsQueryHookResult = ReturnType<typeof useGetPRsQuery>;
export type GetPRsLazyQueryHookResult = ReturnType<typeof useGetPRsLazyQuery>;
export type GetPRsQueryResult = Apollo.QueryResult<GetPRsQuery, GetPRsQueryVariables>;
export const GetUserBankAccountsDocument = gql`
    query GetUserBankAccounts {
  getUserBankAccounts {
    payoutAccounts {
      ...AdminUserBankAccountResponseLoadFragment
    }
  }
}
    ${AdminUserBankAccountResponseLoadFragmentFragmentDoc}`;

/**
 * __useGetUserBankAccountsQuery__
 *
 * To run a query within a React component, call `useGetUserBankAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserBankAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserBankAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserBankAccountsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserBankAccountsQuery, GetUserBankAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserBankAccountsQuery, GetUserBankAccountsQueryVariables>(GetUserBankAccountsDocument, options);
      }
export function useGetUserBankAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserBankAccountsQuery, GetUserBankAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserBankAccountsQuery, GetUserBankAccountsQueryVariables>(GetUserBankAccountsDocument, options);
        }
export type GetUserBankAccountsQueryHookResult = ReturnType<typeof useGetUserBankAccountsQuery>;
export type GetUserBankAccountsLazyQueryHookResult = ReturnType<typeof useGetUserBankAccountsLazyQuery>;
export type GetUserBankAccountsQueryResult = Apollo.QueryResult<GetUserBankAccountsQuery, GetUserBankAccountsQueryVariables>;
export const GetUsersDocument = gql`
    query GetUsers {
  getUsers {
    users {
      ...AdminUserResponseLoadFragment
    }
  }
}
    ${AdminUserResponseLoadFragmentFragmentDoc}`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;