import React from 'react';
import BaseLayout from '../BaseLayout/BaseLayout';

const Dashboard = () => {
  return (
    <BaseLayout drawer={true}>
      <div style={{ height: 300, width: '100%' }}>hello paid backstage</div>
    </BaseLayout>
  );
};

export default Dashboard;
