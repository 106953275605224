import React from 'react';
import Box from '@mui/material/Box';
import { useGetCashRegisterReceiptQuery } from '../../graphql';

import BaseLayout from '../BaseLayout/BaseLayout';
import { useSearchParams } from 'react-router-dom';
import parse from 'html-react-parser';

export const ReceiptLayout: React.FC = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const { data } = useGetCashRegisterReceiptQuery({
    variables: {
      cashRegisterId: parseInt(id || '0'),
    },
  });

  return (
    <BaseLayout drawer={true}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: 'white',
        }}>
        {parse(data?.getCashRegisterReceipt.cashRegisterReceipt || '<h1>Loading ...</h1>')}
      </Box>
    </BaseLayout>
  );
};
