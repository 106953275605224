import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import Copyright from '../../components/CopyRight';
import BaseLayout from '../BaseLayout/BaseLayout';
import { useAdminAuthMutation } from '../../graphql';
import { useStoreActions } from '../../stores';

export default function SignIn() {
  const navigate = useNavigate();

  // class states =========================
  const [userEmail, setUserEmail] = React.useState('');
  const [userPassword, setUserPassword] = React.useState('');

  // User Store =========================
  const setUserDataStore = useStoreActions(actions => actions.user.update);
  const resetUserData = useStoreActions(actions => actions.user.reset);

  const [adminAuthLogin, { loading }] = useAdminAuthMutation({
    variables: {
      email: userEmail,
      password: userPassword,
    },
    onCompleted: data => {
      const {
        adminAuth: { user, token },
      } = data;

      setUserDataStore({
        id: user.id,
        email: user.email,
        token: token,
      });

      navigate('/');
    },
    onError: () => {
      resetUserData();
    },
  });
  // class functions =========================
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (userEmail && userPassword) {
      adminAuthLogin();
      event.preventDefault();
    }
  };

  return (
    <BaseLayout drawer={false}>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Avatar sx={{ m: 1, bgcolor: 'red' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            value={userEmail}
            fullWidth
            disabled={loading}
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={event => {
              const { value } = event.target;
              setUserEmail(value);
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            disabled={loading}
            value={userPassword}
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={event => {
              const { value } = event.target;
              setUserPassword(value);
            }}
          />
          <Button
            disabled={loading}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}>
            Sign In
          </Button>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </BaseLayout>
  );
}
