import React from 'react';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridToolbar,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { useGetInvoicesQuery } from '../../graphql';

import BaseLayout from '../BaseLayout/BaseLayout';
import { toRp } from '../../utils/dataFormatters';
import { Button } from '@mui/material';

const columns: GridColDef[] = [
  {
    field: 'invoice.id',
    headerName: 'ID',
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => params.row.invoice.id,
  },
  {
    field: 'invoice.user.id',
    headerName: 'Issuer user',
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => params.row.invoice.user.id,
  },
  {
    field: 'invoice.user.email',
    headerName: 'issuer email',
    flex: 1,
  },
  {
    field: 'mmm',
    headerName: 'Open Merchant',
    flex: 1,
    renderCell: (params: GridRenderCellParams<GridRowParams>) => (
      <Button
        href={`https://service-payment.paidapp.id/paidapp_admin/merchant_detail.jsf?merchant_id=${params.row.merchantId}`}
        target={'_blank'}
        referrerPolicy={'no-referrer'}
        variant="outlined">
        Open Merchant
      </Button>
    ),
  },
  {
    field: 'invoice.reference',
    headerName: 'Reference',
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => params.row.invoice.reference,
  },
  {
    field: 'invoice.clientName',
    headerName: "Issuer's Client Name",
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => params.row.invoice.clientName,
  },
  {
    field: 'invoice.invoiceNumber',
    headerName: 'Invoice Number',
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => params.row.invoice.invoiceNumber,
  },
  {
    field: 'invoice.pdfUrl',
    headerName: 'PDF link',
    flex: 1,
    renderCell: (params: GridValueGetterParams) => (
      <Button variant="outlined" target={'_blank'} href={params.row.invoice.pdfUrl}>
        DOWNLOAD
      </Button>
    ),
  },
  {
    field: 'invoice.total',
    headerName: 'Total',
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => toRp(params.row.invoice.total),
  },
  {
    field: 'invoice.fulfilled',
    headerName: 'Is fulfilled?',
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => params.row.invoice.fulfilled,
  },
  {
    field: 'invoice.includeFees',
    headerName: 'Include fee ?',
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => params.row.invoice.includeFees,
  },
  {
    field: 'invoice.updatedAt',
    headerName: 'Updated at',
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => params.row.invoice.updatedAt,
  },
];

export const InvoiceListLayout: React.FC = () => {
  const { data, loading } = useGetInvoicesQuery();
  return (
    <BaseLayout drawer={true}>
      <DataGrid
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        loading={loading}
        autoHeight={true}
        rows={data?.getInvoices.invoices || []}
        columns={columns}
      />
    </BaseLayout>
  );
};
