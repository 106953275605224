import React from 'react';
import { StoreProvider } from 'easy-peasy';
import store from './stores';
import './App.css';
import { AppRouter } from './router/AppRouter';
import { ApolloContextProvider } from './ApolloContextProvider';
import { AppRehydratationProvider } from './providers/AppRehydratationProvider';

function App() {
  return (
    <StoreProvider store={store}>
      <AppRehydratationProvider>
        <ApolloContextProvider>
          <AppRouter />
        </ApolloContextProvider>
      </AppRehydratationProvider>
    </StoreProvider>
  );
}

export default App;
