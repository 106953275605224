import React from 'react';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridToolbar,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { useGetPRsQuery } from '../../graphql';

import BaseLayout from '../BaseLayout/BaseLayout';
import { dateToReadable, toRp } from '../../utils/dataFormatters';
import { Button } from '@mui/material';

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', flex: 1 },
  {
    field: 'paymentRequest.user.id',
    headerName: 'Issuer user',
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => params.row.paymentRequest.user.id,
  },
  {
    field: 'paymentRequest.user.email',
    headerName: 'issuer email',
    flex: 1,
    renderCell: (params: GridRenderCellParams<GridRowParams>) =>
      params.row.paymentRequest.user.email,
  },
  {
    field: 'mmm',
    headerName: 'Open Merchant',
    flex: 1,
    renderCell: (params: GridRenderCellParams<GridRowParams>) => (
      <Button
        href={`https://service-payment.paidapp.id/paidapp_admin/merchant_detail.jsf?merchant_id=${params.row.merchantId}`}
        target={'_blank'}
        referrerPolicy={'no-referrer'}
        variant="outlined">
        Open Merchant
      </Button>
    ),
  },
  {
    field: 'paymentRequest.reference',
    headerName: 'Reference',
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => params.row.paymentRequest.reference,
  },
  {
    field: 'paymentRequest.name',
    headerName: "Issuer's Client Name",
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => params.row.paymentRequest.name,
  },
  {
    field: 'paymentRequest.email',
    headerName: "Issuer's Client Eamil",
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => params.row.paymentRequest.email,
  },
  {
    field: 'paymentRequest.notice',
    headerName: 'Notice',
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => params.row.paymentRequest.notice,
  },
  {
    field: 'paymentRequest.amount',
    headerName: 'Total',
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => toRp(params.row.paymentRequest.amount),
  },
  {
    field: 'paymentRequest.isFullFilled',
    headerName: 'Is Fulfilled?',
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => params.row.paymentRequest.isFullFilled,
  },
  {
    field: 'paymentRequest.fees',
    headerName: 'Including fee?',
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => params.row.paymentRequest.fees,
  },
  {
    field: 'paymentRequest.updatedAt',
    headerName: 'Updated at',
    flex: 1,
    valueGetter: (params: GridValueGetterParams) =>
      dateToReadable(params.row.paymentRequest.updatedAt),
  },
];

export const PRListLayout: React.FC = () => {
  const { data, loading } = useGetPRsQuery();

  return (
    <BaseLayout drawer={true}>
      <DataGrid
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        loading={loading}
        autoHeight={true}
        rows={data?.getPRs.PRs || []}
        columns={columns}
      />
    </BaseLayout>
  );
};
