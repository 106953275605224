import { action, Action } from 'easy-peasy';
import { DEFAULT_LANGUAGE } from '../utils/Translate';

export interface Locale {
  lang: string;
}

export interface FCMTokenUpdatePayload {
  token: string;
}

export interface LocaleStore {
  data: Locale;
  update: Action<LocaleStore, Locale>;
  reset: Action<LocaleStore>;
}

export const localeStore: LocaleStore = {
  data: {
    lang: DEFAULT_LANGUAGE,
  },
  update: action((state, payload) => {
    state.data = payload;
  }),
  reset: action(state => {
    state.data = {
      lang: DEFAULT_LANGUAGE,
    };
  }),
};
