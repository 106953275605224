import React from 'react';
import {
  Drawer,
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import { People, DocumentScannerSharp, Receipt, AccountBalance } from '@mui/icons-material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { DrawerHeader, drawerWidth } from '../layouts/BaseLayout/styled';
import { useNavigate } from 'react-router-dom';

interface Props {
  open: boolean;
  handleDrawerClose: any;
}

const drawerContentArray = [
  { name: 'Users', navigation: '/users', icon: <People /> },
  { name: 'Cash Registers', navigation: '/cash-registers', icon: <DocumentScannerSharp /> },
  { name: 'Invoices', navigation: '/invoices', icon: <DocumentScannerSharp /> },
  { name: 'Payment R.s', navigation: '/prs', icon: <Receipt /> },
  { name: 'User Bank Accounts', navigation: '/bank-accounts', icon: <AccountBalance /> },
];

export default function DrawerContent({ open, handleDrawerClose }: Props) {
  const navigate = useNavigate();

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}>
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>{<ChevronLeftIcon />}</IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {drawerContentArray.map(route => (
          <ListItem onClick={() => navigate(route.navigation)} button key={route.name}>
            <ListItemIcon>{route.icon}</ListItemIcon>
            <ListItemText primary={route.name} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
}
