import { action, Action } from 'easy-peasy';

export interface UI {
  isDarkMode: boolean;
}

export interface UIStore {
  data: UI;
  updateIsDarkMode: Action<UIStore, boolean>;
}

export const uiStore: UIStore = {
  data: {
    isDarkMode: true,
  },
  updateIsDarkMode: action((state, payload) => {
    state.data.isDarkMode = payload;
  }),
};
