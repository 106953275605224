import React from 'react';
import { useStoreState } from './stores';
import './App.css';
import { ApolloProvider } from '@apollo/client';
import { useApollo } from './config/ApolloClientConfig';

export const ApolloContextProvider: React.FC = ({ children }) => {
  const token = useStoreState(state => state.user.data.token);
  const [getApolloClient] = useApollo(token);

  return <ApolloProvider client={getApolloClient()}>{children}</ApolloProvider>;
};
