import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import { getConfig } from '../utils/Config';

export const useApollo = (token: string) => {
  const httplink = new HttpLink({
    uri: getConfig().url,
    headers: {
      authorization: token,
    },
  });

  const getApolloConfig = () => {
    return new ApolloClient({
      cache: new InMemoryCache(),
      link: httplink,
    });
  };

  return [getApolloConfig];
};
