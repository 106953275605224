import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginLayout from '../layouts/LoginLayout/LoginLayout';
import DashboardLayout from '../layouts/DashboardLayout/DashboardLayout';
import { UserLayout } from '../layouts/UserListLayout/UserListLayout';
import { InvoiceListLayout } from '../layouts/InvoiceListLayout/InvoiceListLayout';
import { PRListLayout } from '../layouts/PRListLayout/PRListLayout';
import { BankAccountsLayout } from '../layouts/BankAccountListLayout/BankAccountListLayout';
import { CashRegisterListLayout } from '../layouts/CashRegisterListLayout/CashRegisterListLayout';
import { ReceiptLayout } from '../layouts/CashRegisterListLayout/ReceiptLayout';

export const AppRouter: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginLayout />} />
        <Route path="/" element={<DashboardLayout />} />
        <Route path="/users" element={<UserLayout />} />
        <Route path="/cash-registers" element={<CashRegisterListLayout />} />
        <Route path="/receipt" element={<ReceiptLayout />} />
        <Route path="/invoices" element={<InvoiceListLayout />} />
        <Route path="/prs" element={<PRListLayout />} />
        <Route path="/bank-accounts" element={<BankAccountsLayout />} />
      </Routes>
    </Router>
  );
};
