import React from 'react';
import {
  DataGrid,
  GridToolbar,
  GridValueFormatterParams,
  GridColumns,
  GridRowParams,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import { useGetUsersQuery, GetUsersQuery } from '../../graphql';
// import DeleteIcon from '@mui/icons-material/Delete';

import BaseLayout from '../BaseLayout/BaseLayout';
import { Button } from '@mui/material';

const toRp = (value: number) => `Rp. ${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}`;

export const UserLayout: React.FC = () => {
  // const [deleteUserId, setDeleteUserId] = React.useState<string>('');
  const [userData, setUserData] = React.useState<GetUsersQuery>();
  const [loading, setLoading] = React.useState<boolean>(true);
  // const [test, setTest] = React.useState<string>('');

  // console.log('deleteUserId', deleteUserId);

  useGetUsersQuery({
    onCompleted: res => {
      if (res) {
        setUserData(res);
        setLoading(false);
      } else {
        // setTest(JSON.stringify(res));
      }
    },
    onError: err => {
      // setTest(JSON.stringify(err));
      console.log(err);
    },
  });

  const columns: GridColumns = [
    { field: 'id', headerName: 'ID', flex: 1 },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
    },
    {
      field: 'invoice.user.email',
      headerName: 'issuer email',
      flex: 1,
    },
    {
      field: 'mmm',
      headerName: 'Open Merchant',
      flex: 1,
      renderCell: (params: GridRenderCellParams<GridRowParams>) => (
        <Button
          href={`https://service-payment.paidapp.id/paidapp_admin/merchant_detail.jsf?merchant_id=${params.row.merchantId}`}
          target={'_blank'}
          referrerPolicy={'no-referrer'}
          variant="outlined">
          Open Merchant
        </Button>
      ),
    },
    { field: 'companyType', headerName: 'Company type', flex: 1, hide: true },
    { field: 'companyName', headerName: 'company name', flex: 1 },
    { field: 'referralId', headerName: 'referralId', flex: 1, hide: true },
    {
      field: 'merchantId',
      headerName: 'merchantId',
      hide: true,
      flex: 1,
    },
    { field: 'invoiceCount', headerName: 'Iv c.', flex: 1 },
    { field: 'paymentCount', headerName: 'Pr c.', flex: 1 },
    {
      field: 'invoiceValue',
      headerName: 'Iv value',
      flex: 1,
      valueFormatter: (value: GridValueFormatterParams) => toRp(value.value),
    },
    {
      field: 'prValue',
      headerName: 'Pr value',
      flex: 1,
      valueFormatter: (value: GridValueFormatterParams) => toRp(value.value),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
    },
    // {
    //   field: 'isAccountDeletionRequested',
    //   headerName: 'Deletion Request',
    //   flex: 1,
    // },
    // {
    //   field: 'accountDeletionRequestDate',
    //   headerName: 'Date Deletion Request',
    //   flex: 1,
    // },
    // {
    //   field: 'action',
    //   type: 'actions',
    //   getActions: (params: GridRowParams) => [
    //     <GridActionsCellItem
    //       icon={<DeleteIcon />}
    //       key={params.id}
    //       showInMenu
    //       label="Delete"
    //       disabled={
    //         !params.row.isAccountDeletionRequested || params.row.status === User_Status.Deleted
    //       }
    //       onClick={() => {
    //         setDeleteUserId(params.row.id.toString());
    //         // console.warn(params.id)
    //       }}
    //     />,
    //   ],
    // },
  ];

  return (
    <BaseLayout drawer={true}>
      {/* <div>{test}</div> */}
      <DataGrid
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        loading={loading}
        autoHeight={true}
        rows={userData?.getUsers.users || []}
        columns={columns}
      />
    </BaseLayout>
  );
};
