export enum ENVIROMENT {
  PRODUCTION = 'production',
  STAGING = 'staging',
  DEVELOPMENT = 'development',
}

export interface EnviromentUrlValues {
  [ENVIROMENT.PRODUCTION]: string;
  [ENVIROMENT.STAGING]: string;
  [ENVIROMENT.DEVELOPMENT]: string;
}

export interface Config {
  url: EnviromentUrlValues;
  version: string;
}

export interface ConfigValue {
  url: string;
  version: string;
}

const config: Config = {
  url: {
    [ENVIROMENT.PRODUCTION]: 'https://api.paidapp.id/graphql',
    [ENVIROMENT.STAGING]: 'https://api.staging.paidapp.id/graphql',
    [ENVIROMENT.DEVELOPMENT]: 'http://localhost:4000/graphql',
  },
  version: '1.0.0',
};

export const getConfig = (): ConfigValue => {
  const eviroment: ENVIROMENT =
    (process.env.REACT_APP_STAGE as ENVIROMENT) || ENVIROMENT.DEVELOPMENT;
  console.log('enviroment', eviroment);
  return {
    url: config.url[eviroment],
    version: config.version,
  };
};
