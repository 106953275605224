import { action, Action } from 'easy-peasy';

export interface UserData {
  token: string;
  id: number;
  email: string;
}

export interface UserStore {
  data: UserData;
  update: Action<UserStore, UserData>;
  reset: Action<UserStore>;
}

const initialData: UserData = {
  token: '',
  id: 0,
  email: '',
};

export const userStore: UserStore = {
  data: initialData,
  update: action((state, payload) => {
    state.data = payload;
  }),
  reset: action(state => {
    state.data = initialData;
  }),
};
