import React from 'react';
import {
  DataGrid,
  GridToolbar,
  GridColumns,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid';
import { useGetUserBankAccountsQuery, GetUserBankAccountsQuery } from '../../graphql';

import BaseLayout from '../BaseLayout/BaseLayout';

export const BankAccountsLayout: React.FC = () => {
  const [userAccountsData, setUserAccountsData] = React.useState<GetUserBankAccountsQuery>();
  const [loading, setLoading] = React.useState<boolean>(true);
  // const [test, setTest] = React.useState<string>('');

  useGetUserBankAccountsQuery({
    onCompleted: res => {
      if (res) {
        setUserAccountsData(res);
        setLoading(false);
      } else {
        // setTest(JSON.stringify(res));
      }
    },
    onError: err => {
      // setTest(JSON.stringify(err));
      console.log(err);
    },
  });

  const columns: GridColumns = [
    { field: 'userId', headerName: 'userID', flex: 1 },
    {
      field: 'userEmail',
      headerName: 'User Email',
      flex: 1,
      renderCell: (params: GridRenderCellParams<GridRowParams>) => (
        <strong>
          <a
            style={{ color: 'white' }}
            href={`https://service-payment.staging.paidapp.id/paidapp_admin/merchant_detail.jsf?merchant_id=${params.row.merchantId}`}>
            {params.row.userEmail}
          </a>
        </strong>
      ),
    },
    { field: 'accountName', headerName: 'Account Name', flex: 1 },
    { field: 'accountType', headerName: 'Account type', flex: 1 },
    { field: 'accountNumber', headerName: 'Account Number', flex: 1 },
    { field: 'isDefault', headerName: 'Default', flex: 1 },
  ];

  return (
    <BaseLayout drawer={true}>
      {/* <div>{test}</div> */}
      <DataGrid
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        loading={loading}
        autoHeight={true}
        rows={userAccountsData?.getUserBankAccounts.payoutAccounts || []}
        columns={columns}
      />
    </BaseLayout>
  );
};
