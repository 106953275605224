import React from 'react';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridToolbar,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { useGetCashRegistersQuery } from '../../graphql';

import BaseLayout from '../BaseLayout/BaseLayout';
import { toRp } from '../../utils/dataFormatters';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const CashRegisterListLayout: React.FC = () => {
  const navigate = useNavigate();
  const { data, loading } = useGetCashRegistersQuery();

  const columns: GridColDef[] = [
    {
      field: 'cashRegister.id',
      headerName: 'ID',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => params.row.cashRegister.id,
    },
    {
      field: 'cashRegister.user.id',
      headerName: 'Issuer user',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => params.row.cashRegister.user.id,
    },
    {
      field: 'cashRegister.user.email',
      headerName: 'issuer email',
      valueGetter: (params: GridValueGetterParams) => params.row.cashRegister.user.email,
      flex: 1,
    },
    {
      field: 'mmm',
      headerName: 'Open Merchant',
      flex: 1,
      renderCell: (params: GridRenderCellParams<GridRowParams>) => (
        <Button
          href={`https://service-payment.paidapp.id/paidapp_admin/merchant_detail.jsf?merchant_id=${params.row.merchantId}`}
          target={'_blank'}
          referrerPolicy={'no-referrer'}
          variant="outlined">
          Open Merchant
        </Button>
      ),
    },
    {
      field: 'nnn',
      headerName: 'Open Receipt',
      flex: 1,
      renderCell: (params: GridRenderCellParams<GridRowParams>) => (
        <Button
          onClick={() => navigate(`/receipt?id=${params.row.cashRegister.id}`)}
          href="#"
          referrerPolicy={'no-referrer'}
          variant="outlined">
          Open Receipt
        </Button>
      ),
    },
    {
      field: 'cashRegister.reference',
      headerName: 'Reference',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => params.row.cashRegister.reference,
    },
    {
      field: 'cashRegister.cashRegisterNumber',
      headerName: 'Receipt Number',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => params.row.cashRegister.cashRegisterNumber,
    },
    {
      field: 'cashRegister.total',
      headerName: 'Total',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => toRp(params.row.cashRegister.total),
    },
    {
      field: 'cashRegister.fulfilled',
      headerName: 'Is fulfilled?',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => params.row.cashRegister.fulfilled,
    },
    {
      field: 'cashRegister.includeFees',
      headerName: 'Include fee ?',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => params.row.cashRegister.includeFees,
    },
    {
      field: 'cashRegister.updatedAt',
      headerName: 'Updated at',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => params.row.cashRegister.updatedAt,
    },
  ];

  return (
    <BaseLayout drawer={true}>
      <DataGrid
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        loading={loading}
        autoHeight={true}
        rows={data?.getCashRegisters?.cashRegisters || []}
        columns={columns}
      />
    </BaseLayout>
  );
};
