import React, { useEffect } from 'react';
import { ThemeProvider, Switch, IconButton, Box, Toolbar, Typography } from '@mui/material';
import { darkTheme, lightTheme } from '../../utils/UIConfig';
import { useStoreActions, useStoreState } from '../../stores';

import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuIcon from '@mui/icons-material/Menu';
import DrawerContent from '../../components/DrawerContent';
import { AppBar, DrawerHeader, Main } from './styled';
import { isEmpty } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';

interface Props {
  children: React.ReactNode;
  drawer: boolean;
}

function App({ children, drawer }: Props) {
  const [open, setOpen] = React.useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const isDarkmodeStore = useStoreState((state: any) => state.ui.data.isDarkMode);
  const token = useStoreState(state => state.user.data.token);
  const setUserDataStore = useStoreActions((actions: any) => actions.ui.updateIsDarkMode);
  const [isDarkMode, setIsDarkMode] = React.useState(isDarkmodeStore);

  useEffect(() => {
    console.log('token', token);
    if (isEmpty(token) && !location.pathname.includes('login')) {
      navigate('/login');
    }
  });

  const handleChangeDarkMode = () => {
    setUserDataStore(!isDarkMode);
    setIsDarkMode(!isDarkMode);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar>
            {drawer ? (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ mr: 2, ...(open && { display: 'none' }) }}>
                <MenuIcon />
              </IconButton>
            ) : null}

            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              PA.ID Backstage
            </Typography>
            <FormControlLabel
              control={<Switch onChange={handleChangeDarkMode} defaultChecked />}
              label="Dark Mode"
            />
          </Toolbar>
        </AppBar>
        <DrawerContent open={open} handleDrawerClose={handleDrawerClose} />
        <Main open={open}>
          <DrawerHeader />
          <CssBaseline />
          {children}
        </Main>
      </Box>
    </ThemeProvider>
  );
}

export default App;
